import React from 'react'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import AltSection from '../components/columnAlternating'

const PartnerworldPage = ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          gradientClass="mask d-flex justify-content-center align-items-center gradient"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          forealt=""
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}        
          type="root"
        />

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <div className="font-w-400 text-xs-extra-large text-opening-para" dangerouslySetInnerHTML={{ __html: post.frontmatter.openingpara }} />
                  <div className="mt-3 richtext divlink" dangerouslySetInnerHTML={{ __html: post.html }} />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.section && (
            <section className="bg-white-grey-gradient">
              <MDBContainer>
                {post.frontmatter.section.map((services, index) => {
                  return (
                    <div>
                      <AltSection
                        key={index}
                        title={services.title}
                        subtitle={services.subtitle}
                        description={services.description}
                        image={services.image.childImageSharp.gatsbyImageData}
                        alt={services.alttext}
                        placement={services.placement}
                        titleClass="font-alt font-w-400 letter-spacing-1 mb-3 pt-3 title-xs-medium title-medium text-very-dark-blue"
                        link=""
                        anchor={services.link}
                        colour="ficon-dark-blue"
                      />
                    </div>
                  )
                })}
              </MDBContainer>
            </section>
          )}
      </div>
    </Layout>
  )
}
export default PartnerworldPage

export function Head({data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query {
    markdownRemark(
      frontmatter: { template: { eq: "other" }, name: { eq: "partnerworld-advantage" } }
    ) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        alttext
        ctatext
        ctaslug             
        image {
          childImageSharp {
            gatsbyImageData(width: 1920, quality: 90)
          }
        }
        openingpara
        section {
          title
          subtitle
          image {
            childImageSharp {
              gatsbyImageData(width: 850, quality: 90)
            }
          }
          alttext
          placement
          description
          linktext
          link
        }
      }
      html
    }
    cardimage: file(name: { eq: "unigw-desktop-ipad-wbgc" }) {
      childImageSharp {
        gatsbyImageData(width: 1000, quality: 90)
      }
    }
  }
`
